import request from "@/utils/request";

//创建待办集
export function createTodoList(data) {
  return request({
    url: "/todo/list/add",
    method: "post",
    data,
  });
}

//创建待办项
export function createTodoItem(data) {
  return request({
    url: "/todo/item/add",
    method: "post",
    data,
  });
}

//获取待办集列表
export function getTodoList(orderId) {
  return request({
    url: "/todo/list/listByOrderId",
    method: "get",
    params: {
      orderId
    },
  });
}

//获取用户待办日程
export function getTodoSchedule(data) {
  return request({
    url: "/businessTodo/todoSchedule",
    method: "post",
    data: data,
  });
}

export function getUserAndCompanySchedule(params) {
  return request({
    url: "/todo/item/list",
    method: "get",
    params,
  });
}

// 获取周待办
export function getUserWeekCompanySchedule(params) {
  return request({
    url: "/todo/item/listWeek",
    method: "get",
    params,
  });
}

//删除待办集
export function deleteTodoList(unid) {
  return request({
    url: "/todo/list/delete",
    method: "post",
    params: {
      unid
    },
  });
}

//删除待办项
export function deleteTodoItem(unid) {
  return request({
    url: "/todo/item/delete",
    method: "post",
    params: {
      unid
    },
  });
}

//更新待办集
export function updateTodoList(data) {
  return request({
    url: "/todo/list/update",
    method: "post",
    data,
  });
}

//更新待办项
export function updateTodoItem(data) {
  return request({
    url: "/businessTodo/updateItem",
    method: "post",
    data: data,
  });
}

// 获取待办项详情
export function getTodoItemDetail(todoItemId) {
  return request({
    url: "/todo/item/detail",
    method: "get",
    params: {
      todoItemId
    },
  });
}

// 待办已读
export function getTodoItemRead(todoItemId) {
  return request({
    url: "/todo/item/read",
    method: "post",
    params: {
      todoItemId
    },
  });
}
