<template>
  <el-drawer
    :title="todoData.ci"
    :append-to-body="true"
    :before-close="handleClose"
    :visible.sync="innerDrawer"
    direction="rtl"
    size="362px"
    @open="open"
    v-move-outside
  >
    <div class="content">
      <ul class="subject">
        <li>
          <span class="text">{{ $t('xiaoxie.Title') }}</span>
          <div class="text_content">
            {{ todoData.title || todoData.content }}
          </div>
        </li>
        <li>
          <span class="text topMove">{{ $t('InformationCenter.assignedto') }}</span>
          <div class="assign_list">
            <div
              v-for="(item, index) in todoData.assignUserList"
              :key="index"
              class="assign_list_item"
            >
              <member
                :info="item"
                :size="28"
                :isDone="onIsDone(item)"
                :placement="'bottom'"
              />
              <span>{{ item.name }}</span>
            </div>
          </div>
        </li>
        <li>
          <span class="text topMove">{{
            $t('InformationCenter.Whendonenotify')
          }}</span>
          <ul class="assign_list">
            <li
              v-for="(item, index) in todoData.notifyUserList"
              :key="index"
              class="assign_list_item"
            >
              <member :info="item" :size="28" :placement="'bottom'" />
              <span>{{ item.name }}</span>
              <!-- {{ todoData && todoData.notifyUserList && index === todoData.notifyUserList.length - 1 ? '' : '' }} -->
            </li>
          </ul>
        </li>
        <li>
          <span class="text">{{ $t('InformationCenter.Dueon') }}</span>
          <div class="text_content">{{ todoData.ddlDate }}</div>
        </li>
        <li>
          <span class="text">{{ $t('InformationCenter.Notes') }}</span>
          <div class="text_content" style="white-space: pre-wrap;">{{ todoData.notes }}</div>
        </li>
        <li>
          <span class="text">{{ $t('InformationCenter.Status') }}</span>
          <div class="text_content">
            <span
              class="status"
              :class="isFinish().isTrue ? 'active' : ''"
            >
              {{
                isFinish().count == 0
                  ? $t('InformationCenter.UNSTARTED')
                  : isFinish().isTrue
                  ? $t('InformationCenter.FINISHED')
                  : $t('InformationCenter.UNFINISHED')
              }}
              {{ isFinish().text }}
            </span>
          </div>
        </li>
        <li>
          <span class="text">{{ $t('InformationCenter.Createby') }}</span>
          <div class="text_content">
            <span>{{
              todoData && todoData.createUser && todoData.createUser.nickName
            }}</span>
          </div>
        </li>
      </ul>
      <div class="record">
        <div class="record_top">{{ $t('InformationCenter.Record') }}</div>
        <ul class="item_log_list">
          <li v-for="(item, index) in itemLogList" :key="index">
            <div class="item_user">
              <member :info="item.updatePerson" :size="40" />
              <div class="item_user_text">
                <div class="item_user_name_box">
                  <div class="item_user_name">
                    {{ item.updatePerson.nickName }}
                  </div>
                  <div>{{ isStatus(item) }}</div>
                </div>
              </div>
            </div>
            <span>{{ item.updateTime }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="button" v-if="todoData.isOperation != 1">
      <el-button 
        class="cancel_button" 
        size="small"
        @click="handleClose"
      >{{ $t('Forwarder.Cancel') }}</el-button>
      <el-button
        size="small"
        type="primary"
        @click="finishTodo()"
        :loading="buttonLoading"
      >
        {{
          currentTodo && currentTodo.todoStatus == '2'
            ? $t('order.to previous step')
            : $t('InformationCenter.Finished')
        }}
      </el-button>
    </div>
  </el-drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import { itemLogList, receiveItem } from '../../api/news';
import { getTodoItemDetail, getTodoItemRead } from "@/api/todo";

export default {
  name: 'todoInnerDrawer',
  data() {
    return {
      todoData: {
        ci: '',
        content: '',
        assignUserItemList: [],
        assignUserList: [],
        notifyUserList: [],
        notes: '',
        createUser: { name: '', profile: { savePath: '' } },
        toDoItemId: '',
        isOperation: 1,
        todoStatus: false,
      },
      itemLogList: [],
      buttonLoading: false,
      recordLoading: false,
    };
  },
  props: ['todoItemId', 'innerDrawer'],
  computed: {
    ...mapGetters(['businessUserId']),
    isStatus() {
      return function (item) {
        if(item.todoStatus === '0') return '创建';
        if(item.todoStatus === '1') return '进行中';
        if(item.todoStatus === '2') return '已完成';
      }
    },
    currentTodo() {
      return this.todoData.assignUserList.find(item => item.openId == this.businessUserId);
    }
  },
  methods: {
    open() {
      this.recordLoading = true;
      getTodoItemRead(this.todoItemId).then((data) => {
        if(data) {
          this.$store.dispatch("user/setReadStatus", "READTODO");
        }
      })
      getTodoItemDetail(this.todoItemId).then((data) => {
        let obj = {
          ci: data.ci || data.pi,
          title: data.content,
          assignUserItemList: data.assignUsers || [],
          assignUserList: data.assignUsers,
          notifyUserList: data.notifyUsers,
          ddlDate: data.ddlDate,
          notes: data.notes,
          createUser: data.createPerson,
          toDoItemId: data.todoItemId,
          isLight: data.todoStatus,
        }
        this.todoData = { ...obj, isOperation: this.distinguish(obj) }
        if (this.todoData.toDoItemId) {
          itemLogList(this.todoData.toDoItemId).then((data) => {
            this.itemLogList = data;
          }).finally(() => (this.recordLoading = false));
        }
      })
    },
    distinguish(data) {
      const { assignUserList, notifyUserList } = data;
      let assignIndex;
      let notifyIndex;
      if(assignUserList.length) {
        assignIndex = assignUserList.findIndex((v) => {
          return v.openId == this.businessUserId;
        });
      }
      if(notifyUserList.length) {
        notifyIndex = notifyUserList.findIndex((v) => {
          return v.openId == this.businessUserId;
        });
      }
      let index = 1;
      if (assignIndex != -1 && notifyIndex != -1) {
        index = 3;
      } else if (assignIndex != -1) {
        index = 2;
      } else if (notifyIndex != -1) {
        index = 1;
      }
      return index;
    },
    handleClose() {
      this.$emit('handleClose');
    },
    //To-do状态扭转
    finishTodo() {
      this.buttonLoading = true;
      let ask_msg = '';
      if (this.currentTodo.todoStatus == '2') {
        ask_msg = this.$t('home.Back to UNFINISHED?');
      } else {
        ask_msg = this.$t('home.Finshed this todo?');
      }
      this.$confirm(ask_msg, this.$t('home.Todo status update'), {
        confirmButtonText: this.$t('home.Confirm'),
        cancelButtonText: this.$t('home.Cancel'),
        cancelButtonClass: 'cancel-btn',
      })
        .then(() => {
          receiveItem({
            todoItemId: this.todoData.toDoItemId, 
            todoStatus: this.currentTodo.todoStatus == '2' ? 1 : 2
          })
            .then(() => {
              this.$emit('afterUpdateTodo');
              this.$emit('handleClose');
              this.$message.success(this.$t('order.State torsion succeeded'));
              getTodoItemRead(this.todoItemId).then((data) => {
                if(data) {
                  this.$store.dispatch("user/setReadStatus", "READTODO");
                }
              })
            })
            .finally(() => (this.buttonLoading = false));
        })
        .finally(() => {
          this.buttonLoading = false;
        });
    },
    isFinish() {
      const { assignUserList } = this?.todoData;
      let count = 0;
      if (assignUserList?.length) {
        assignUserList.forEach((v) => {
          if (v.todoStatus == '2') {
            count++;
          }
        });
      }
      let data = {
        isTrue: count == assignUserList.length,
        count,
        text:
          count == assignUserList.length || count == 0
            ? ''
            : `(${count + '/' + assignUserList.length})`,
      };
      return data;
    },
    //查询那些人完成了
    onIsDone(data) {
      const { assignUserList } = this.todoData;
      let isTrue = false;
      if (assignUserList.length) {
        const { openId } = data;
        assignUserList.forEach((res) => {
          if (res.openId == openId && res.todoStatus == '2') {
            isTrue = true;
          }
        });
      }
      return isTrue;
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  background: #F7F9FC;
  height: calc(100% - 61px);
  overflow: overlay;
  .subject {
    padding: 20px;
    background: #ffffff;
    li {
      margin-top: 16px;
      display: flex;
      // align-items: center;
      justify-content: space-between;
      position: relative;

      .text {
        width: 80px;
        font-size: 14px;
        line-height: 28px;
        font-weight: 400;
        color: #637381;
        &.topMove {
          margin-top: 5px;
        }
      }

      .assign_list {
        flex: 1;
        display: flex;
        flex-wrap: wrap;

        .assign_list_item {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 600;
          color: rgba(46, 63, 79, 1);
          margin: 0 10px 0 0;
          white-space: nowrap;

          img {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin-right: 4px;
          }

          span {
            max-width: 101px;
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }

      .text_content {
        flex: 1;
        font-size: 14px;
        font-weight: 500;
        line-height: 28px;
        color: #122545;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .createBox {
          display: flex;
          align-items: center;
        }
      }

      .status {
        padding: 4px 16px;
        background: #E3E9F1;
        font-size: 14px;
        line-height: 28px;
        font-weight: 500;
        color: #637381;
        border-radius: 50px;
        .active {
          background: #48AC42;
          color: #ffffff;
        }
      }
    }

    li:nth-child(1) {
      margin-top: 0;
    }

    li:nth-child(2),
    li:nth-child(3) {
      margin-top: 8px;
    }
  }

  .record {
    padding: 16px 20px;

    .record_top {
      font-size: 14px;
      font-weight: 400;
      color: rgba(46, 63, 79, 0.8);
    }

    .item_log_list {
      margin-top: 11px;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        font-size: 12px;
        line-height: 17px;
        font-weight: 400;
        color: #757D8A;

        .item_user {
          display: flex;
          align-items: center;
          img {
            width: 24px;
            height: 24px;
            border-radius: 50%;
          }
          .item_user_text {
            .item_user_name_box {
              display: flex;
              align-items: center;
              font-size: 14px;
              line-height: 20px;
              color: #122545;
              font-weight: 500;
              .item_user_name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 80px;
              }
            }

            .item_user_name {
              margin: 0 5px;
            }
          }
        }
      }
    }
  }
}

.button {
  padding: 12px 20px 16px 20px;
  width: 100%;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #E0E4EA;
  text-align: right;
}
</style>
