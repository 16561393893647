<template>
  <div class="scheduleBox">
    <div class="scheduleContent">
      <div class="leftBox">
        <div class="scheduleTypeTabs">
          <div
            class="item"
            v-for="(item, index) in typeOtions"
            :key="index"
            :class="item.value == scheduleType ? 'active' : ''"
            @click="scheduleType = item.value"
          >
            {{ $t(`scheduleNew.${item.key}`) }}
          </div>
        </div>
        <div class="scheduleContentBox" v-loading="todoLoading">
          <div class="scheduleToggle">
            <button @click="dateToggle()">◀</button>
            <span>
              {{
                scheduleType
                  ? dayjs(scheduleDate).format("YYYY")
                  : dayjs(scheduleDate).format("YYYY-MM")
              }}
            </span>
            <button @click="dateToggle('next')">▶</button>
          </div>
          <template v-if="!scheduleType">
            <el-calendar v-model="scheduleDate">
              <template slot="dateCell" slot-scope="{ data }">
                <div class="calendayDayBox" @click="setTodoItem(data.day)">
                  {{ dayjs(data.day).format("DD") }}
                  <div
                    class="dropTodo"
                    :class="
                      availabilityArrangements(
                        dayjs(data.day).format('YYYY-MM-DD')
                      ).length > 0
                        ? 'active'
                        : ''
                    "
                  />
                </div>
              </template>
            </el-calendar>
          </template>
          <template v-else>
            <div class="el-calendar">
              <div class="el-calendar__body">
                <table cellspacing="0" cellpadding="0" class="el-calendar-table">
                  <tbody>
                    <tr class="el-calendar-table__row tableGrid">
                      <td
                        :class="`current ${index == weekActive ? 'is-selected' : ''} ${dayjs(scheduleDate).format('YYYY') == dayjs().format('YYYY') && item == dayjs().week() ? 'is-today': ''}`"
                        v-for="(item, index) in weekData"
                        :key="index"
                        @click="currentTodo(item, index)"
                      >
                        <div class="el-calendar-day">
                          <div class="calendayDayBox">
                            {{ index + 1 }}
                            <div
                              class="dropTodo"
                              :class="item.todo ? 'active' : ''"
                            ></div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="rightBox">
        <div class="currentDate">
          {{ !scheduleType ? dayjs(scheduleDate).format("YYYY-MM-DD") : $tc('weekfn', weekActive + 1) }}
          <span class="tag">{{ todoDayData.length }}</span>
          <p v-if="scheduleType">{{ dayjs(scheduleDate).week(weekActive + 1).startOf('week').format("YYYY/MM/DD") + ' - ' + dayjs(scheduleDate).week(weekActive + 1).endOf('week').format("YYYY/MM/DD") }}</p>
        </div>
        <div
          class="item"
          v-for="(item, index) in todoDayData"
          :key="index"
          :class="
            item.todoStatus == '0' || item.todoStatus == '1'
              ? 'ongoing'
              : 'complete'
          "
          @click="openInnerDrawer(item)"
        >
          <div class="title">{{ item.content }}</div>
          <div class="timeRemark">
            <span>{{ item.ddlDate }} | 备注：{{ item.notes || "_ _" }}</span>
          </div>
        </div>
      </div>
    </div>
    <todoInnerDrawer
      :innerDrawer.sync="innerDrawer"
      :innerItem="innerItem"
      :todoItemId="todoItemId"
      @handleClose="handleClose"
      @afterUpdateTodo="
        getScheduleTodoData({
          startTime: dayjs(this.scheduleDate)
            .startOf('month')
            .format('YYYY-MM-DD HH:mm:ss'),
          endTime: dayjs(this.scheduleDate)
            .endOf('month')
            .format('YYYY-MM-DD HH:mm:ss'),
        })
      "
    />
  </div>
</template>
<script>
import {
  getUserAndCompanySchedule,
  getUserWeekCompanySchedule,
} from "@/api/todo";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isoWeeksInYear from "dayjs/plugin/isoWeeksInYear";
import isLeapYear from "dayjs/plugin/isLeapYear";
import weekYear from "dayjs/plugin/weekYear";
import todoInnerDrawer from "@/components/todoDrawer/todoInnerDrawer.vue";

dayjs.extend(weekYear);
dayjs.extend(weekOfYear);
dayjs.extend(isLeapYear);
dayjs.extend(isoWeeksInYear);

export default {
  name: "Schedule",
  components: {
    todoInnerDrawer,
  },
  data() {
    return {
      dayjs: dayjs,
      todoItemId: null,
      todoLoading: false,
      scheduleType: 0,
      typeOtions: [
        {
          key: "tm",
          value: 0,
        },
        {
          key: "wk",
          value: 1,
        },
      ],
      isSetTodoItem: false,
      scheduleDate: dayjs().format("YYYY-MM-DD"),
      companyId: "",
      weekList: "",
      todoData: [],
      todoDayData: [],
      weekData: [],
      weekActive: null,
      innerItem: null,
      innerDrawer: false,
    };
  },
  mounted() {
    this.companyId = this.$route.query.companyID;
    this.weekList = dayjs(
      `${dayjs(this.scheduleDate).format("YYYY")}-01`
    ).isoWeeksInYear();
    this.getScheduleTodoData({
      startTime: dayjs(this.scheduleDate)
        .startOf("month")
        .format("YYYY-MM-DD HH:mm:ss"),
      endTime: dayjs(this.scheduleDate)
        .endOf("month")
        .format("YYYY-MM-DD HH:mm:ss"),
    }, true);
  },
  watch: {
    scheduleDate(val, old) {
      if (!this.scheduleType) {
        this.getScheduleTodoData({
          startTime: dayjs(val).startOf("month").format("YYYY-MM-DD HH:mm:ss"),
          endTime: dayjs(val).endOf("month").format("YYYY-MM-DD HH:mm:ss"),
        });
      } else {
        this.getScheduleTodoData({
          startTime: `${dayjs(val).format("YYYY")}-01-01 00:00:00`,
          endTime: `${dayjs(val).format("YYYY")}-12-31 23:59:59`,
          type: "day",
        });
      }
    },
    scheduleType() {
      this.getScheduleTodoData({
        startTime: dayjs()
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss"),
        endTime: dayjs()
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss"),
      }, true);
      this.scheduleDate = dayjs().format("YYYY-MM-DD HH:mm:ss")
    },
  },
  methods: {
    currentTodo(row, index) {
      this.weekActive = index;
      this.todoLoading = true;
      getUserAndCompanySchedule({
        startTime: row.startTime,
        endTime: row.endTime,
        companyId: this.companyId,
      }).then((data) => {
        this.todoDayData = data;
      }).finally(() => {
        this.todoLoading = false;
      });
    },
    dateToggle(type) {
      if (!this.scheduleType) {
        if (type === "next") {
          this.scheduleDate = dayjs(this.scheduleDate)
            .add(1, "month")
            .format("YYYY-MM-DD");
        } else {
          this.scheduleDate = dayjs(this.scheduleDate)
            .subtract(1, "month")
            .format("YYYY-MM-DD");
        }
        this.isSetTodoItem = true;
      } else {
        if (type === "next") {
          this.scheduleDate = dayjs(this.scheduleDate)
            .add(1, "year")
            .format("YYYY");
        } else {
          this.scheduleDate = dayjs(this.scheduleDate)
            .subtract(1, "year")
            .format("YYYY");
        }
      }
    },
    getScheduleTodoData(form, isOne = false) {
      this.todoLoading = true;
      if(this.scheduleType == '0') {
        getUserAndCompanySchedule({
          ...form,
          companyId: this.companyId,
        }).then((data) => {
          this.todoData = data;
          console.log(isOne || this.isSetTodoItem)
          if(isOne || this.isSetTodoItem) {
            this.setTodoItem(dayjs(this.scheduleDate).format("YYYY-MM-DD"));
            this.isSetTodoItem = false;
          }
        }).finally(() => {
          this.todoLoading = false;
        });
        return;
      }
      getUserWeekCompanySchedule({
        companyId: this.companyId,
        year: dayjs(this.scheduleDate).format("YYYY")
      }).then((data) => {
        this.weekData = data;
        let index = this.weekActive !== null ? this.weekActive : dayjs().week() - 1;
        let row = this.weekData.filter((item, idx) => idx == index)[0];
        this.currentTodo(row, index);
      });
    },
    availabilityArrangements(date) {
      let dataFilter = this.todoData.filter(
        (item) => dayjs(item.ddlDate).format("YYYY-MM-DD") == date
      );
      return dataFilter;
    },
    setTodoItem(data) {
      this.todoDayData = this.availabilityArrangements(data);
    },
    openInnerDrawer(item) {
      let obj = {
        ci: this.$t("scheduleNew.todoDetail"),
        title: item.content,
        assignUserItemList: item.assignUsers || [],
        assignUserList: item.assignUsers,
        notifyUserList: item.notifyUsers,
        ddlDate: item.ddlDate,
        notes: item.notes,
        createUser: item.createPerson,
        toDoItemId: item.todoItemId,
        isLight: item.todoStatus,
      };
      this.todoItemId = item.todoItemId;
      this.innerItem = { ...obj, isOperation: this.distinguish(obj) };
      this.innerDrawer = true;
    },
    handleClose() {
      this.innerDrawer = false;
    },
    distinguish(data) {
      const { assignUserList, notifyUserList } = data;
      let assignIndex;
      let notifyIndex;
      if (assignUserList.length) {
        assignIndex = assignUserList.findIndex((v) => {
          return v.openId == this.businessUserId;
        });
      }
      if (notifyUserList.length) {
        notifyIndex = notifyUserList.findIndex((v) => {
          return v.openId == this.businessUserId;
        });
      }
      let index = 1;
      if (assignIndex != -1 && notifyIndex != -1) {
        index = 3;
      } else if (assignIndex != -1) {
        index = 2;
      } else if (notifyIndex != -1) {
        index = 1;
      }
      return index;
    },
  },
};
</script>

<style lang="less" scoped>
.scheduleBox {
  width: 100%;
  padding: 40px;
  .scheduleTypeTabs {
    display: flex;
    align-items: center;
    .item {
      width: 128px;
      height: 53px;
      border-radius: 4px 4px 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 16px;
      line-height: 24px;
      &.active {
        background: #ffffff;
        font-weight: 600;
      }
    }
  }
  .scheduleContent {
    display: flex;
    gap: 20px;
    .leftBox {
      width: 60%;
      .tableGrid {
        display: grid;
        grid-template-columns: repeat(7,1fr);
        justify-items: center;
        td {
          width: 100%;
        }
      }
      .scheduleContentBox {
        width: 100%;
        height: calc(100vh - 210px);
        background: #ffffff;
        padding: 12px;
        overflow: overlay;
        .scheduleToggle {
          display: flex;
          justify-content: center;
          column-gap: 24px;
          font-size: 20px;
          font-weight: 600;
          color: #2e3f4f;
          background-color: white;
          margin-top: 10px;
          button {
            color: #2e3f4f3b;
            background-color: transparent;
            border: none;
            cursor: pointer;
          }
        }
        /deep/ .el-calendar__header {
          display: none;
        }
        /deep/ .el-calendar__body {
          padding: 0;
          margin-top: 20px;
        }
        /deep/.el-calendar-table {
          thead {
            th {
              text-align: center;
              font-weight: 900;
              color: #076f49;
            }
          }
          td {
            border: none;
            font-weight: 900;
            &.is-selected {
              .el-calendar-day {
                background-color: #076f49 !important;
                color: #fff !important;
              }
            }
            .el-calendar-day {
              width: 100%;
              height: 80px;
              border-radius: 4px;
              padding: 0;
              .calendayDayBox {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
              }
              .dropTodo {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: #48ac42;
                display: none;
                position: absolute;
                top: 12px;
                right: 12px;
                &.active {
                  display: block;
                }
              }
              &:hover {
                background: #f3f3f3;
              }
            }
          }
        }
      }
    }
    
    .rightBox {
      width: 40%;
      height: calc(100vh - 210px);
      overflow: overlay;
      .currentDate {
        height: 53px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        color: #122545;
        font-weight: 500;
        .tag {
          width: 22px;
          height: 16px;
          background: #DC3545;
          border-radius: 4px 4px 4px 0;
          color: #ffffff;
          margin: 0 10px;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        p {
          width: 100%;
          font-size: 14px;
          color: #757d8a;
          line-height: 1;
          position: relative;
          top: -5px;
        }
      }
      .item {
        width: 100%;
        padding: 12px 17px;
        background: #ffffff;
        margin-bottom: 12px;
        border-radius: 4px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 4px;
          height: 100%;
        }
        &.complete {
          &::before {
            background: #e0e4ea;
          }
          .title {
            color: #637381;
          }
          .timeRemark {
            color: #757d8a;
          }
        }
        &.ongoing {
          &::before {
            background: #48ac42;
          }
          .title {
            color: #122545;
          }
          .timeRemark {
            color: #48ac42;
          }
        }
        .title {
          font-size: 16px;
          line-height: 28px;
          font-weight: 500;
        }
        .timeRemark {
          font-size: 14px;
          line-height: 28px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
